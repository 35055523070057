<template>
  <div>
    <b-card>
      <search-card />
    </b-card>
  </div>
</template>
<script>
import { BCard } from 'bootstrap-vue'
import SearchCard from '@/views/Expertise/Components/SearchCard.vue'

export default {
  name: 'Search',
  components: { SearchCard, BCard },

}
</script>
